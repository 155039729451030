import { defineStore } from "pinia";


export const MENU_NAME = "menu";

export const useMenuStore = defineStore(MENU_NAME, {
    state: () => ({
        _activeIndex: null,
    }),
    actions: {
        setActiveIndex(value:number|string) {
            this._activeIndex = value;
        },
        unsetActiveIndex() {
            this._activeIndex = null;
        }
    },
    getters: {
        activeIndex: (state) =>  state._activeIndex,
    },
});